














































































































































import { defineComponent } from "vue";
import InputMask from "@/components/UI/InputMask.vue";
import { RequestStatusEnum } from "@/models/global/RequestStatus.enum";
import {
  TemplateFormInterface,
  TemplatesTableHeaderInterface
} from "@/models/store/TemplatesTableHeader.interface";
import { RemoveTemplateRequestInterface } from "@/models/api/template/RemoveTemplateRequest.interface";
import { RenameTemplateRequestInterface } from "@/models/api/template/RenameTemplateRequestInterface";
import useVuelidate from "@vuelidate/core";
import { computed, reactive, ref } from "vue";
import BaseInput from "@/components/UI/BaseInput.vue";
import TemplateService from "@/models/order/Template.service";
import { useUserStore } from "@/store/useUserStore";

export default defineComponent({
  components: { InputMask, BaseInput },
  setup() {
    const _templateService = new TemplateService();

    const form = reactive<TemplateFormInterface>({
      name: "",
      id: "",
      createDate: 0,
      modifyDate: 0,
      lastUseDate: null
    });
    const rules = computed(() => {
      return {
        name: {}
      };
    });
    const v$ = useVuelidate(rules, form);
    const userStore = useUserStore();

    const reqStatus = ref<RequestStatusEnum>(RequestStatusEnum.Pending);
    const reqMessage = ref<string>("");
    const templatesLoading = ref<boolean>(false);
    const templateForEdit = ref<TemplateFormInterface | null>(null);
    const templateForEditNewName = ref<string | null>(null);
    const showRemoveTemplateDialog = ref<boolean>(false);
    const showEditTemplateNameDialog = ref<boolean>(false);

    const loading = computed<boolean>(() => {
      return reqStatus.value === RequestStatusEnum.Loading;
    });
    const templates = computed<TemplateFormInterface[]>(() => {
      return userStore.templates.filter(
        (x: TemplateFormInterface) =>
          !form.name || x.name.toLowerCase().includes(form.name.toLowerCase())
      );
    });
    const templatesTableHeaders: TemplatesTableHeaderInterface[] = [
      {
        text: "Название шаблона",
        value: "name",
        sortable: true
      },
      {
        text: "Дата обновления",
        value: "modifyDate",
        sortable: true
      },
      {
        text: "Дата создания",
        value: "createDate",
        sortable: true
      },
      {
        text: "Дата последнего использования",
        value: "lastUseDate",
        sortable: true
      },
      {
        text: "Действия",
        value: "action",
        sortable: false
      }
    ];

    const loadTemplates = async () => {
      templatesLoading.value = true;

      await userStore.getTemplates();

      templatesLoading.value = false;
    };

    const remove = async (command: RemoveTemplateRequestInterface) => {
      try {
        reqStatus.value = RequestStatusEnum.Loading;

        const res = await _templateService.removeTemplate(command);

        if (!res.isSuccess) {
          reqStatus.value = RequestStatusEnum.Error;
          reqMessage.value = res.message;

          return;
        }

        await loadTemplates();

        reqStatus.value = RequestStatusEnum.Success;
        reqMessage.value = "Успех";
      } catch (e) {
        reqStatus.value = RequestStatusEnum.Error;
      }
    };

    const onCancelRemoveTemplateDialog = async () => {
      showRemoveTemplateDialog.value = false;
    };

    const onConfirmRemoveTemplateDialog = async () => {
      if (!templateForEdit.value) return;

      const command: RemoveTemplateRequestInterface = {
        id: templateForEdit.value.id
      };

      await remove(command);
      await loadTemplates();
      showRemoveTemplateDialog.value = false;
    };

    const onCancelEditTemplateNameDialog = async () => {
      showEditTemplateNameDialog.value = false;
    };

    const edit = async (command: RenameTemplateRequestInterface) => {
      try {
        reqStatus.value = RequestStatusEnum.Loading;

        const res = await _templateService.renameTemplate(command);

        if (!res.isSuccess) {
          reqStatus.value = RequestStatusEnum.Error;
          reqMessage.value = res.message;

          return;
        }

        await loadTemplates();

        reqStatus.value = RequestStatusEnum.Success;
        reqMessage.value = "Успех";
      } catch (e) {
        reqStatus.value = RequestStatusEnum.Error;
      }
    };

    const onConfirmEditTemplateNameDialog = async () => {
      if (!templateForEdit.value) return;

      const command: RenameTemplateRequestInterface = {
        templateId: templateForEdit.value.id,
        name: templateForEditNewName.value ?? "Новое название"
      };

      await edit(command);
      await loadTemplates();
      showEditTemplateNameDialog.value = false;
    };

    const showEditDialog = async (item: TemplateFormInterface) => {
      templateForEdit.value = item;
      templateForEditNewName.value = item.name;

      showEditTemplateNameDialog.value = true;
    };

    const showRemoveDialog = async (item: TemplateFormInterface) => {
      templateForEdit.value = item;
      showRemoveTemplateDialog.value = true;
    };

    return {
      form,
      reqStatus,
      reqMessage,
      templatesLoading,
      v$,
      templatesTableHeaders,
      loading,
      userStore,
      showRemoveTemplateDialog,
      showEditTemplateNameDialog,
      templateForEdit,
      templateForEditNewName,
      templates,
      edit,
      loadTemplates,
      showRemoveDialog,
      onCancelRemoveTemplateDialog,
      onConfirmRemoveTemplateDialog,
      remove,
      showEditDialog,
      onConfirmEditTemplateNameDialog,
      onCancelEditTemplateNameDialog
    };
  },
  mounted() {
    this.loadTemplates();
  }
});
